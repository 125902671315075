body {
  font-family: 'Roboto', monospace, sans-serif;
}

h2 {
  color:#363636;
}

.root {
  width: 100vw;
  position: absolute;
  bottom: 0;
  background-color: #fff0 !important;
}


.App {
  text-align: center;
}

#mapid { height: 180px; }

.mainContent {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.centerHelp {
  display: contents;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.iconDefault {
  color: #363636;
  font-size : 40px;
}

.iconDefault:hover + .navText {
  color: #363636;
  transform: scale(1.1);
  transform-origin: center
}

.iconDefault:hover {
  color: #363636;
  transform: scale(1.1);
  transform-origin: center
}

.navText { 
  color: #363636;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.navText:hover {
  transform: scale(1.1);
  transform-origin: center
}

.expText { 
  color: #363636;
  font-size: 0.8em;
  font-weight: 200;
  letter-spacing: .1em;
}

.pitchText { 
  color: #363636;
  font-weight: 400;
  font-size: 1.4rem;
  padding-bottom: 20px;
}

.siteHeadline {
  margin-top: 15vh;
  color: #363636;
  font-weight: 700;
  font-size: 4em;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  margin-block-end: 0.1em;
}

.siteSubHeadline {
  margin-top: 15vh;
  color: #363636;
  font-weight: 700;
  font-size: 2em;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  padding-left: 5px;
  padding-right: 5px;
}

.backgroundSetting {
  background-image: url('./img/iml25.jpeg');
  background-position: center center;
  background-size: cover;
  height: 100vh;
  flex-grow: 1;
}

.backgroundSetting-qm {
  background-image: url('./img/bg6.jpg');
  background-position: center center;
  background-size: cover;
  height: 100vh;
  flex-grow: 1;
}

.backgroundSetting-contact {
  background-image: url('./img/iml13.jpeg');
  background-position: center center;
  background-size: cover;
  height: 100vh;
  flex-grow: 1;
}


.backgroundOverlay {
  background-image: linear-gradient(183deg,rgb(255 255 255 / 55%) 0,rgb(255 255 255 / 90%) 25%,rgb(249 249 249 / 72%) 45%,rgb(255 255 255 / 80%));
  height: 100%;
}

.headerNav {
  color: #363636;
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding: 10px;
}



.blurBtn {
  border-radius: 5px;
  border-color: #363636;
  border-color: var(--color-white);
  background-color: hsla(0,0%,100%,.4) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.blurBtn span {
  color: #363636;
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.MuiButton-text {
  padding: 10px 20px !important;
}

.blurBtn span:hover {
  transform: scale(1.1);
  transform-origin: center
}

.processOffer {
  overflow: scroll;
  padding-top: 120px !important;
}

.cardItem {
  width: 100px;
  height: 120px;
  border-radius: 5px;
  border: 1.5px solid white !important;
  border-color: white;
  border-color: var(--color-white);
  background-color: hsla(0,0%,100%,.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.cardItem-lg {
  height: auto;
  min-height: 40vh;
  max-height: 30vh;
  overflow: hidden;
  padding-bottom: 20px;
  border-radius: 5px;
  border: 1.5px solid white !important;
  border-color: white;
  border-color: var(--color-white);
  background-color: hsla(0,0%,100%,.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.cardItem-qs {
  min-height: 200px;
  width: 45%;
  padding-bottom: 20px;
  border-radius: 5px;
  border: 1.5px solid white !important;
  border-color: white;
  border-color: var(--color-white);
  background-color: hsla(0,0%,100%,.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  
}

.processCard {
    position:relative;
    margin: 5px;
    width: 16%;
    border-radius: 5px;
    border: 1.5px solid white  !important;
    border-color: white;
    border-color: var(--color-white);
    backdrop-filter: blur(10px);
    display: flex;
}

.processCard2 {
  position:relative;
  margin: 5px;
  width: 25%;
  border-radius: 5px;
  border: 1.5px solid white !important;
  border-color: white;
  border-color: var(--color-white);
  backdrop-filter: blur(10px);
  display: flex;
}

.processPic {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.floatText {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
}

.colorOverlay {
  background-image: linear-gradient(180deg,rgba(0,0,0,.55) 0,rgba(0,0,0,.4) 25%,rgba(0,0,0,.3) 45%,rgba(0,0,0,.8));
  
  box-sizing: border-box;
  width: 100%;
  flex: none;
  position: absolute;
  top: 0;
  left: 0;
 
  height:100%;
  
}

.cardPic {
  width: 100%;
}

.PrivacyPolicy-Main {
  padding-top: 72px;
}

.PrivacyPolicy-list {
  padding: 0;
}

.PrivacyPolicy-item {
  line-height: 1.5;
  letter-spacing: normal;
  color: #363636;
  margin: 0 0 8px;
}

.PrivacyPolicy-item {
  list-style-type: none;
}

.policySpacer {
  position: absolute;
  bottom: -30px;
  right: 10px;
  color: #363636;
}

.bottomNav {
  position: absolute;
  bottom: 30px;
}

.typeform { 
  top: 50px !important;
  height: calc(70vh -50px) !important;
}


.hamburger {
  color: #363636;
}

.hamburgerBtn {
  display: none !important;
}

.landingContainer {
  margin-top: 30%;
  height:100%;
  display: contents;
}

.navRuler {
  border-bottom: 1px solid black;
  margin: 20px;
  padding:20px;
}

@media only screen and (max-width: 800px) {
  .mainContent {
    height: 100%;
  }

  .hamburgerBtn {
    display: block !important;
    position: absolute !important;
    top: 0;
    right: 0;
  }

  .siteSubHeadline {
    margin-top: 50px;
  }

  
  .processOffer {
    padding-top: 0px !important;
  }

  .bottomNav {
    display: none !important;
    position: absolute;
    bottom: 0px;
  }
  
  
  .siteHeadline {
    font-size: 1.4em;
    width: 80%;
  }
  .pitchText {
    font-size: 1em;
    width: 80%
  }
  .headerNav {
    display: none;
  }
  .processCard {
    width: 80%;
  }
  .processCard2 {
    width: 80%;
  }
  .respWrap {
    
    height: 70%;
    overflow: scroll;
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    flex-wrap: nowrap !important;
  }

  .typeform { 
    top: 50px !important;
    height: calc(90vh -50px) !important;
  }

  .backgroundSetting {
    height: 100%;
    min-height: 100vh;
  }

  .backgroundOverlay {
    min-height: 100vh;
  }
  
  .cardItem-lg {
    max-height: 100vh;
    margin: 5px  !important;
  }
}